const NPI_OFFSET = 24;

export default function validateNpi(npiString) {
  if (npiString.length !== 10) {
    return false;
  }
  const digits = npiString.split("").map((x) => parseInt(x));
  const checkDigit = digits[9];

  //1. Take the first 9 digits of the NPI number and double the value of alternate digits beginning with the rightmost digit.

  //2. Sum the individual digits of the products resulting from step 1 to the unaffected digits from the original number and add a constant 24.
  const sum = digits.slice(0, 9).reduce(luhnSum, 0) + NPI_OFFSET;

  //3. Subtract the total obtained in step 2 from the next higher number ending in zero, this is the check digit. If the number obtained in step 2 ends in zero, the check digit is zero.
  const value = (10 - (sum % 10)) % 10;

  return checkDigit === value;
}

export function checkDigit(prefix) {
  const digits = prefix.split("").map((x) => parseInt(x));
  const sum = digits.slice(0, 9).reduce(luhnSum, 0) + NPI_OFFSET;
  return (10 - (sum % 10)) % 10;
}

function luhnSum(acc, cur, idx) {
  if (idx % 2 === 0) {
    cur *= 2;
  }

  return acc + sumDigits(cur);
}

export function sumDigits(number) {
  return number
    .toString()
    .split("")
    .map((x) => parseInt(x))
    .reduce((acc, cur) => acc + cur);
}
