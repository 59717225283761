import React from "react";
import { generateNPI } from "../../helpers/generateNpi";
import ShowNpi from "../ShowNpi";
import "./styles.scss";
export default function GenerateNpis(props) {
  const npis = Array.from({ length: props.n }, (_, i) => (
    <ShowNpi key={i} number={generateNPI()} />
  ));

  return (
    <div>
      <h2>Randomly generated NPI Numbers</h2>
      <div className="npi-list">{npis}</div>
    </div>
  );
}
