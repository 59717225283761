import { checkDigit } from "./validateNpi";

export function generateNPI() {
  const digits = [];
  for (let i = 0; i < 9; i += 1) {
    digits.push(randomDigit());
  }
  const prefix = digits.join("");
  return prefix + checkDigit(prefix).toString();
}

function randomDigit() {
  return Math.floor(Math.random() * 10);
}
