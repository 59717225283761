import React, { useState } from "react";
import validateNpi, { checkDigit } from "../../helpers/validateNpi";
import "./styles.scss";
export default function ValidateNpi(props) {
  const [q, setQ] = useState("");

  const update = (e) => {
    const { value } = e.target;

    setQ(value.replace(/[^0-9]*/g, ""));
  };

  let digits = q.substring(0, 10).split("");

  if (q.length < 10) {
    const prefix = q.substring(0, 9).padEnd(9, "0");
    digits = (prefix + checkDigit(prefix).toString()).split("");
  }

  let tooltipText = "Copy";
  const copy = () => {
    console.log(digits.join(""));
  };

  return (
    <div className="validate-npi">
      <h2>Test/create NPI</h2>
      <div className="field">
        <input type="text" value={q} onChange={update} maxLength={10} />
      </div>
      <div className="display" onClick={copy}>
        <div className="digits">
          {digits.map((x, i) => (
            <span key={i} className="digit">
              {x}
            </span>
          ))}
        </div>
        <div className="check">
          {validateNpi(digits.join("")) ? "valid" : "not valid"}
        </div>
        <div className="tooltip">{tooltipText}</div>
      </div>
    </div>
  );
}
