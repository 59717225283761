import React from "react";
import validateNpi from "../../helpers/validateNpi";
import "./styles.scss";
export default function ShowNpi({ number }) {
  return (
    <div className="show-npi">
      <div className="number">{number}</div>
      <div className="meta">
        {" "}
        <span className="check">
          {validateNpi(number) ? "valid" : "not valid"}
        </span>{" "}
        <span className="lookup">
          <a href={`https://npidb.org/npi-lookup/?npi=${number}`}>Look up</a>
        </span>
      </div>
    </div>
  );
}
