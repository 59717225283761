import React from "react";
import ValidateNpi from "./components/ValidateNpi";
import GenerateNpis from "./components/GenerateNpis";
import "./styles.scss";
function App() {
  return (
    <div>
      <h1>NPI Tool</h1>
      <ValidateNpi />
      <GenerateNpis n={2000} />
    </div>
  );
}

export default App;
